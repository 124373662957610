<template>
  <section v-if="!connectorModelId || items">
    <PanelHeaderEquipmentList
      v-if="header"
      :title="title"
      :nav="nav"
      :connector_id="connectorId"
      icon="fa fa-dashboard"
    />
    <div
      class="tab-pane active"
      role="tabpanel"
      aria-labelledby=""
      v-if="items && deletedItems && isEmpty && showEmptyPanel && !busy"
    >
      <EmptyListAlert
        buttonLabel="add_new_connector"
        importLabel="import_connectors"
        @import="importConnectors()"
        :newItemPath="newItemPath"
      />
    </div>
    <template v-else-if="items">
      <div class="tab-pane active" id="edit" role="tabpanel" aria-labelledby="">
        <FileExplorer
          ref="fileExplorer"
          :items="allItems"
          :modal="false"
          :multiSelection="multiSelection"
          :title="`${maxResult} ${$tc('connector', maxResult > 1 ? 2 : 1)}`"
          :maxResult="maxResult"
          :discarded="discarded"
          @change="tree = $event"
          @open="onOpen"
          @close="onClose"
          @drop="resetSearch"
          @beforeRestore="resetSearch"
          @sidebarResize="onSidebarResize"
          dbKey="tree_connector"
          :sidebarEnabled="!$utils.isMobile() && !connectorModelId"
        >
          <template #search>
            <div style="padding: 15px 10px 5px 10px">
              <SearchableTableQueryInput
                ref="query"
                v-if="tree"
                v-model="query"
                :createCommand="$refs.stbl.createCommand"
                @create="create"
              >
                <template
                  #customFilters
                  v-if="
                    contract &&
                    contract.allowed_model_connectors &&
                    !connectorModelId
                  "
                >
                  <div class="row">
                    <div class="form-group col-md-6 custom-filters">
                      <label class="filter-option noselect">
                        <input
                          type="checkbox"
                          v-model="itemFilter.simple_connectors"
                        />
                        <span>{{ $t("connectors") }}</span>
                      </label>
                      <label class="filter-option noselect">
                        <input type="checkbox" v-model="itemFilter.models" />
                        <span>{{ $t("models") }}</span>
                      </label>
                      <label class="filter-option noselect">
                        <input
                          type="checkbox"
                          v-model="itemFilter.model_based_connectors"
                        />
                        <span>{{ $t("model_based_connectors") }}</span>
                      </label>
                    </div>
                  </div>
                </template>
                <template #extraButtons>
                  <button
                    id="export"
                    class="btn btn-default"
                    :disabled="filteredItems && filteredItems.length == 0"
                    @click.stop.prevent="downloadCSV()"
                    :title="$t('hints.button_export')"
                  >
                    <i class="fa fa-file-excel-o" />
                  </button>
                  <button
                    v-if="multiSelection.key"
                    id="mass_remove"
                    class="btn btn-default"
                    :disabled="!nSelected"
                    @click="massRemove"
                    :title="$t('mass_remove')"
                  >
                    <i class="fa fa-trash-o" />
                  </button>
                </template>
                <template #statistics>
                  <ResourceStatistics
                    resource="connector"
                    :total="(items && items.length) || 0"
                    :showing="
                      ($refs.stbl &&
                        $refs.stbl.itemList &&
                        $refs.stbl.itemList.length) ||
                      nItems
                    "
                    icon="fa fa-plug"
                  />
                </template>
              </SearchableTableQueryInput>
            </div>
          </template>
          <template #files>
            <SearchableTable
              :key="selectedNode || 'root'"
              v-show="tree"
              :class="
                $refs.fileExplorer && $refs.fileExplorer.ready
                  ? 'easy-show'
                  : 'easy-hide'
              "
              class="table-container"
              :show="$refs.fileExplorer && $refs.fileExplorer.ready"
              :items="filteredItems"
              :fields="fields"
              :commands="commands"
              :multiColumnOrder="false"
              :clientSort="true"
              :pagination="pagination"
              :maxResult="maxResult"
              :deepSearch="false"
              :loading="busy"
              :disabled="cloneEnabled"
              :multiSelection="multiSelection"
              :searchEnabled="false"
              @select="onSelect"
              @command="onCommand"
              @loadNextPage="onLoadNextPage"
              @multiColumnSort="onMultiColumnSort"
              @nItems="maxResult = $event"
              ref="stbl"
              :style="{
                'margin-top':
                  $refs.stbl && $refs.stbl.showPagination ? '-6px' : '0'
              }"
            >
              <template #paginationBefore>
                <div
                  v-if="!connectorModelId && tree && !tree.show"
                  :class="{ 'pull-left': pagination, clear: 'both' }"
                >
                  <div
                    class="btn-sidepanel-toggle btn btn-default hidden-xs"
                    @click="$refs.fileExplorer.toggle"
                  >
                    <i class="fa fa-list"></i>
                  </div>
                </div>
              </template>
              <template #id="entry" v-if="tree">
                <div
                  class="jstree-draggable"
                  draggable="true"
                  :data-item-id="entry.item.id"
                  :data-item-name="entry.item.name"
                  @mousedown.stop.prevent="$refs.fileExplorer.dragStart"
                >
                  <i :class="draggableIcon"></i>
                  <span> {{ entry.item.id }}</span>
                </div>
              </template>
              <template v-slot:screen_id="entry">
                <ScreenSelectionCell :item="entry.item">
                  <template #before>
                    <i class="fa fa-alarm"></i>
                  </template>
                </ScreenSelectionCell>
              </template>
              <template v-slot:name="entry">
                <span>
                  <i
                    :class="info(entry.item).class"
                    :title="info(entry.item).status"
                  ></i>
                  {{ entry.item.name }}
                </span>
              </template>
              <template v-slot:serial_number="entry">
                <template
                  v-if="entry.item.deleted_at && !entry.item.serial_number"
                >
                  <template v-if="!entry.item.base_model">
                    <span class="text-red">
                      <i class="fa fa-times-circle"></i> {{ $t("removed") }}
                    </span>
                  </template>
                  <template v-else> - </template>
                </template>
                <template
                  v-if="!entry.item.deleted_at && !entry.item.serial_number"
                >
                  <template
                    v-if="!is_mqtt(entry.item) && !entry.item.base_model"
                  >
                    <span class="text-red">
                      <i class="fa fa-warning"></i> {{ $t("pending") }}
                    </span>
                  </template>
                  <template v-else> - </template>
                </template>
                <template v-else>
                  {{ entry.item.serial_number }}
                </template>
              </template>
              <template v-slot:toggle="entry">
                <div v-if="entry.item.deleted_at || entry.item.base_model">
                  -
                </div>
                <ResourceToggle :item="entry.item" v-else />
              </template>
              <template v-slot:info="entry">
                <span
                  :title="info(entry.item).title"
                  style="white-space: nowrap"
                  v-html="info(entry.item).value"
                >
                </span>
              </template>
            </SearchableTable>
          </template>
        </FileExplorer>
      </div>
      <div class="tab-pane" id="list" role="tabpanel" aria-labelledby=""></div>
    </template>
    <ModalImportFile
      :entity="importEntity"
      @close="clearImportEntity"
      @resource_imported="resourceImported"
    />
  </section>
</template>
<script>
import { debounce } from "lodash";
import { isMQTT } from "@/services/connector.js";
import DashboardEditPickerBase from "@/components/registration/dashboard-edit-picker-base.vue";
import SearchableTable from "@/components/searchable-table.vue";
import ResourceStatistics from "@/components/statistics/resource-statistics.vue";
import ConnectorService from "@/services/connector.js";
import EmptyListAlert from "@/components/registration/empty-list-alert.vue";
import ModalImportFile from "@/components/modal-import-file.vue";
import ScreenSelectionCell from "@/components/registration/screen-selection-cell.vue";
import ResourceToggle from "@/components/registration/resource-toggle.vue";
import FileExplorer from "@/components/editor/file-explorer.vue";
import SearchableTableQueryInput from "@/components/searchable-table-query-input.vue";

export default {
  name: "DashboardEditConnectorPicker",
  extends: DashboardEditPickerBase,
  components: {
    SearchableTable,
    ResourceStatistics,
    EmptyListAlert,
    ModalImportFile,
    ScreenSelectionCell,
    ResourceToggle,
    SearchableTableQueryInput,
    FileExplorer
  },
  props: {
    showEmptyPanel: {
      type: Boolean,
      required: false,
      default: true
    },
    connectorModelId: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      tree: null,
      entity: "connector",
      itemFilter: {
        models: false,
        model_based_connectors: false,
        simple_connectors: false
      },
      importEntity: "",
      filtering: false,
      deletedItems: null
    };
  },
  computed: {
    fields() {
      return [
        {
          name: "id",
          title: "id",
          style() {
            return { "white-space": "nowrap" };
          }
        },
        {
          name: "name",
          title: "connector",
          style() {
            return { overflow: "hidden", "text-overflow": "ellipsis" };
          }
        },
        {
          name: "serial_number",
          title: "serial_number",
          parser(item) {
            return item.serial_number || "-";
          },
          style() {
            return { "text-align": "center" };
          }
        },
        {
          name: "location_name",
          title: "location",
          order_id: "location",
          style() {
            // return { "min-width": "100px" };
          },
          parser(item) {
            return item.location_name || "-";
          }
        },
        {
          name: "screen_id",
          title: "screen",
          parser: (device) => {
            let screenId = device?.screen_id || "";
            if (!screenId && device?.connector?.screen_id) {
              screenId = device.connector.screen_id;
            }
            return (screenId && this.getScreenById(screenId)?.name) || "";
          }
        },
        {
          name: "info",
          title: " ",
          sortable: true,
          tooltip: `${this.$t(
            "specific_properties"
          )}:<div class='text-left data-flags'>${this.info()}</div>`,
          style() {
            return { "text-align": "center", "min-width": "40px" };
          },
          parser: (item) => {
            return this.connectorType(item).value;
          }
        },
        {
          name: "toggle",
          title: "enabled",
          parser: (item) => {
            return item.enabled ? 0 : 1;
          },
          style() {
            return { "text-align": "center" };
          }
        }
      ];
    },
    commands() {
      let items = [
        {
          name: "create",
          title: "new",
          icon: "fa fa-plus",
          enabled: () => {
            return this.$can("manage", "EquipamentoCadastro");
          },
          commands: []
        },
        {
          name: "edit",
          title: "edit",
          icon: "fa fa-pencil",
          visible: this.selectedNode != "trash_can",
          enabled: () => {
            return (
              this.selectedNode != "trash_can" &&
              !this.nSelected &&
              this.$can("manage", "EquipamentoCadastro")
            );
          }
        },
        {
          name: "clone",
          title: "clone",
          icon: "fa fa-copy",
          visible: this.selectedNode != "trash_can",
          enabled: () => {
            return (
              this.selectedNode != "trash_can" &&
              !this.nSelected &&
              this.$can("manage", "EquipamentoCadastro")
            );
          }
        },
        {
          name: "restore",
          title: "restore",
          icon: "fa fa-undo",
          visible: this.selectedNode == "trash_can",
          enabled: (item) => {
            return (
              this.selectedNode == "trash_can" &&
              item.id &&
              item.deleted_at &&
              !this.nSelected &&
              this.$can("manage", "EquipamentoCadastro")
            );
          }
        },
        {
          name: "remove",
          title: "remove",
          icon: "fa fa-trash",
          visible: this.selectedNode != "trash_can",
          enabled: () => {
            return (
              this.selectedNode != "trash_can" &&
              !this.nSelected &&
              this.$can("manage", "EquipamentoCadastro")
            );
          }
        },
        { name: "-" },
        {
          name: "open",
          title: "device_list",
          icon: "portal-icon device[solid]",
          visible: this.selectedNode != "trash_can",
          enabled: () => {
            return this.selectedNode != "trash_can" && !this.nSelected;
          }
        },
        {
          name: "addChild",
          title: "add_new_device",
          icon: "fa fa-plus",
          visible: this.selectedNode != "trash_can",
          enabled: (item) => {
            return (
              this.selectedNode != "trash_can" &&
              !this.nSelected &&
              !item.base_model_id &&
              this.$can("manage", "EstacaoCadastro")
            );
          }
        }
      ];
      if ((this.$store.getters["dashboard/connectorList"] || []).length) {
        if (this.$can("manage", "EstacaoCadastro"))
          items[0].commands.push({
            name: "newDevice",
            title: "new_device",
            icon: "fa fa-plus"
          });
        if (this.$can("manage", "DadoCadastro"))
          items[0].commands.push({
            name: "newData",
            title: "new_data",
            icon: "fa fa-plus"
          });
        if (!this.isFreePlan && this.$can("manage", "AlarmeCadastro"))
          items[0].commands.push({
            name: "newAlarm",
            title: "new_alarm",
            icon: "fa fa-plus"
          });
      }
      if (this.$can("manage", "EquipamentoCadastro"))
        items[0].commands.push({
          name: "importConnectors",
          title: "import_connectors",
          icon: "fa fa-plus"
        });
      return items;
    },
    filterOn() {
      return (
        this?.itemFilter?.models ||
        this?.itemFilter?.model_based_connectors ||
        this?.itemFilter?.simple_connectors
      );
    },
    folderMode() {
      // return this.$refs.fileExplorer && this.tree && this.tree.show ? true : false;
      return this.tree && this.tree.show ? true : false;
    },
    selectedNode() {
      return (this.folderMode && this.tree.selectedNode) || "";
    },
    filteredItems() {
      const src = this?.tree?.show ? this.allItems : this.list;
      let lst = (this.$refs.stbl && this.$refs.stbl.search(src || [])) || src;
      lst =
        this.selectedNode && !this.connectorModelId
          ? lst.filter(({ id }) => this.selectedNode === this.tree.leaves[id])
          : lst;
      return this.filterOn
        ? (lst || []).filter(
            (i) =>
              (this.itemFilter.models && i.base_model) ||
              (this.itemFilter.model_based_connectors && i.base_model_id) ||
              (this.itemFilter.simple_connectors &&
                !i.base_model &&
                !i.base_model_id)
          )
        : lst;
    },
    nItems() {
      return this?.connectorModelId ? this.list.length : this.maxResult;
    },
    list() {
      if (this.connectorModelId) {
        return (this.items || []).filter(
          ({ base_model_id }) =>
            parseInt(base_model_id) == parseInt(this.connectorModelId)
        );
      }
      return this.items;
    },
    discarded() {
      return (this.deletedItems || []).map(({ id }) => id);
    },
    allItems() {
      return (this.items || []).concat(this.deletedItems || []);
    },
    newItemPath() {
      let url = `/dashboard/edit/connector/0`;
      if (this?.connectorModelId) {
        url += `?model=${this?.connectorModelId}`;
      }
      return url;
    },
    isEmpty() {
      return !(this?.allItems || []).length;
    },
    query: {
      set(value) {
        if (this.$refs.stbl) {
          this.$refs.stbl.query = value;
        }
        this.findNodes();
      },
      get() {
        return this?.$refs?.stbl?.query || "";
      }
    },
    draggableIcon() {
      return this?.tree?.icons?.leaf || "fa fa-file-o";
    }
  },
  methods: {
    removalMessage(item) {
      let msg = "";

      // In mass remove mode
      if (item && Array.isArray(item)) {
        return this.warningContent(
          "connector",
          this.$tc("n_records_selected", item.length, { amount: item.length }),
          "you_wont_be_able_to_revert_this"
        );
      }

      // Single record remove mode
      if (item?.device?.connector?.base_model) {
        let message = this.$t("removal_model_message");
        let text = this.$t("you_wont_be_able_to_revert_this");
        let field_name = this.$tc("data");
        let value = item.name;
        let cls = "fa fa-exclamation-triangle";
        let warning = `<p>${message}<br/><div class="text-warning"><i class="${cls}"></i> ${text}</div></p>`;
        let html = `<b>${field_name}</b>: ${value}${warning}`;
        msg = this.wrap(html); // can be implemented at child level
      }
      return msg;
    },
    create(command) {
      if (command == "create") {
        let params =
          this.tree && this.tree.show && this.tree.selectedNode
            ? `?parent=${this.tree?.selectedNode}`
            : "";
        this.$router.push(`${this.newItemPath}${params}`);
      } else {
        this.onCommand({ name: command });
      }
    },
    open(command) {
      this.$router.push(
        "/dashboard/edit/connector/" + command.target.id + "/device"
      );
    },
    edit(command) {
      this.$router.push(`/dashboard/edit/connector/${command.target.id}#edit`);
    },
    clone(command) {
      let params =
        this.tree && this.tree.show && this.tree.selectedNode
          ? `?a=c&parent=${this.tree?.selectedNode}`
          : "?a=c";
      this.$router.push(
        `/dashboard/edit/connector/${command.target.id}${params}`
      );
    },
    is_mqtt(item) {
      return isMQTT(item);
    },
    wrap(html) {
      let wrapper = document.createElement("div");
      wrapper.innerHTML = html;
      return wrapper;
    },
    restore(command) {
      if (!command.target) return;
      if (command.target.id > 0) {
        let connector = (this.deletedItems || []).find(
          ({ id }) => id == command.target.id
        );
        if (connector) {
          let content = "";

          if (!connector.base_model && !isMQTT(connector)) {
            content = this.$t("you_really_want_to_restore");
            content = `${content}<br><span class="text-danger"><i class="fa fa-warning"></i>`;
            content = `${content} ${this.$t(
              "you_will_need_to_fill_the_serial_number"
            )}</span>`;
          } else {
            content = this.$t("you_really_want_to_restore");
          }

          this.$swal({
            title: this.$t("are_you_sure"),
            content: this.wrap(content),
            icon: "warning",
            buttons: [this.$t("cancel"), this.$t("restore")],
            dangerMode: true
          }).then((ok) => {
            if (ok) {
              connector.deleted_at = null;
              connector.screen_id =
                connector.screen_id != 1000000000 ? connector.screen_id : null;
              this.service.save(connector).then(() => {
                this.$store.dispatch("dashboard/fetchConnector", {
                  id: connector.id,
                  deep: true
                });
                this.fetch();
                this.fetchDeletedItems();
                this.alert = {
                  title: this.$t("restore"),
                  text: this.$t("you_have_restored_n_items", { count: 1 }),
                  type: "success"
                };
                this.showAlert();
              });
            }
          });
        }
      }
    },
    resourceImported(response, entity) {
      let msg = this.$t("success_import_message");
      this.validateSaveResponse(response, msg);
      this.showAlert();
      if (response && response instanceof Array) {
        this.$emit("resource_imported", entity);
        this.fetchItems().then(() => {
          if (this.selectedNode && this.tree.show) {
            response.forEach(({ id }) => {
              this.$store.dispatch("dashboard/setParent", {
                dbKey: "tree_connector",
                id: id,
                parentId: this.selectedNode
              });
            });
            this.$nextTick(() => {
              if (
                this.$refs?.fileExplorer &&
                this.$refs?.fileExplorer?.items &&
                this.$refs.fileExplorer.items.length &&
                this.tree.show &&
                this.$refs.fileExplorer.show
              ) {
                this.$refs.fileExplorer.show();
              }
            });
          }
        });
      }
    },
    remove(command) {
      let config = {
        item: command.target,
        type: "connector",
        resource_key: "connector_id",
        rule: "EquipamentoEscrita",
        service: this.service
      };
      this.validateAndRemove(config, this.fetchDeletedItems);
    },
    massRemove(command) {
      if (!this.multiSelection.values.length) return;
      let config = {
        items: this.multiSelection.values,
        type: "connector",
        resource_key: "connector_id",
        rule: "EquipamentoEscrita",
        service: this.service
      };
      this.validateAndMassRemove(config, this.fetchDeletedItems);
    },
    addChild(command) {
      this.$router.push(
        "/dashboard/edit/connector/" + command.target.id + "/device/0"
      );
    },
    fetchItems() {
      return new Promise((resolve) => {
        if (this?.connectorModelId && this.connectorList.length) {
          this.$set(this, "items", this.connectorList);
          this.maxResult = this.items.length;
          resolve(this.items);
          return;
        }
        let query = {
          contract_id: this.contract.id
        };
        this.fetch(query).then((ret) => {
          if (ret) {
            this.$store.dispatch("dashboard/setConnectorValue", this.items);
          }
          resolve(this.items);
        });
      });
    },
    fetchDeletedItems() {
      return new Promise((resolve) => {
        let query = {
          contract_id: this.contract.id,
          only_deleted: true
        };
        this.service.fetch(query).then((ret) => {
          if (ret) {
            this.deletedItems = ret;
            this.$set(this, "deletedItems", ret);
          }
          resolve(this.deletedItems);
        });
      });
    },
    onOpen() {
      this.$emit("open");
    },
    onClose() {
      if (this.command) {
        this.$emit(this.command.name, this.command.event);
      }
      this.$emit("close");
      this.command = null;
    },
    resetSearch() {
      this.clearSelection();
      this.itemFilter.models = false;
      this.itemFilter.model_based_connectors = false;
      this.itemFilter.simple_connectors = false;
      this.query = "";
      if (this.$refs.query) this.$refs.query.resetQuery();
    },
    async findNodes() {
      this._search =
        this._search ||
        debounce(() => {
          if (!this.folderMode) return;
          let ids = [];
          const hasQuery =
            this.$utils.trim(this?.$refs?.stbl?.query ?? "") !== "";
          if ((hasQuery || this.filterOn) && this.$refs.stbl) {
            let lst = this.$refs.stbl.search(this.list);
            lst = this.filterOn
              ? (lst || []).filter((i) => {
                  return (
                    (this.itemFilter.models && i.base_model) ||
                    (this.itemFilter.model_based_connectors &&
                      i.base_model_id) ||
                    (this.itemFilter.simple_connectors &&
                      !i.base_model &&
                      !i.base_model_id)
                  );
                })
              : lst;
            ids = lst.map(({ id }) => id);
          }
          this.$refs.fileExplorer.findNodesByLeafId(ids);
        }, 500);
      this._search();
    },
    connectorType(item) {
      let type = this.$tc(
        item.base_model
          ? "model"
          : item.base_model_id
          ? "instance"
          : "connector",
        1
      );
      return { label: type, value: `${type.charAt(0).toUpperCase()}` };
    },
    info(item) {
      if (item) {
        const type = this.connectorType(item);
        return {
          value: type.value,
          title: type.label,
          status:
            type.label +
            ": " +
            this.$tc(
              item.base_model
                ? "not_available"
                : item.is_connected
                ? "connected"
                : "disconnected",
              1
            ),
          class: item.base_model
            ? "fa fa-chain-broken fa-status fa-disabled"
            : item.is_connected
            ? "fa fa-link fa-status fa-connected"
            : "fa fa-chain-broken fa-status fa-disconnected"
        };
      } else {
        return [
          { value: "C", title: this.$tc("connector", 1) },
          { value: "M", title: this.$tc("model", 1) },
          { value: "I", title: this.$tc("instance", 1) }
        ]
          .map((i) => `<div>${i.value} = ${i.title}</div>`)
          .join("");
      }
    },
    onSidebarResize() {
      this.$nextTick(() => {
        if (this.$refs.stbl) {
          this.$refs.stbl.syncColumnResizeHandle();
        }
      });
    }
  },
  watch: {
    items(n, o) {
      if (n && o && n.length != o.length && this.$refs.fileExplorer) {
        this.$nextTick(() => {
          if (
            this.$refs.fileExplorer &&
            n.length == this.$refs.fileExplorer.items.length
          ) {
            if (this.$refs.fileExplorer.syncLeaves) {
              this.$refs.fileExplorer.syncLeaves();
            }
          }
        });
      }
    },
    filterOn() {
      this.findNodes();
    },
    folderMode() {
      this.resetSearch();
    }
  },
  beforeCreate() {
    this.service = new ConnectorService();
    this.$emit("rule", "EquipamentoAcesso");
  },
  created() {
    this.title = "connector_list";
    this.fetchDeletedItems();
  }
};
</script>

<style scoped>
section > .tab-pane {
  margin: 5px 0 30px 0;
  padding: 10px 0 0 0;
  /* overflow-x: auto; */
}
div.search-container {
  padding: 15px 10px 5px 10px;
}
.custom-filters {
  min-width: 100%;
  width: 100%;
  margin: 15px 0px -15px;
  white-space: nowrap;
}
.filter-option {
  margin-right: 15px;
}
.filter-option span {
  vertical-align: top;
  margin-left: 5px;
}
.filter-option:hover {
  cursor: pointer;
  color: #72afd2;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.table-container {
  min-height: 100px;
  max-height: 100%;
  height: calc(100%);
  overflow: visible;
}

@media (max-width: 768px) {
  .table-container {
    overflow: auto;
  }
}
.jstree-draggable {
  white-space: nowrap;
  min-width: 40px;
  display: inline-block;
}
.jstree-draggable > i {
  margin-right: 6px;
}

.fa-opaque {
  opacity: 0.5;
}

.fa-status {
  margin: 0 5px;
}

.fa-connected {
  color: #19c519;
  text-shadow: 0 0 0px #479547;
  font-size: 110%;
}

.fa-disconnected {
  color: #a94442;
}

.fa-disabled {
  color: 0;
  opacity: 0.5;
}

.btn-sidepanel-toggle {
  margin: 0 25px 0 10px;
}

@media (max-width: 768px) {
  .custom-filters {
    white-space: initial;
  }
  #export {
    margin-left: -32px;
  }
}
</style>
