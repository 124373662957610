<template>
  <section>
    <PanelHeaderEquipmentList
      v-if="header"
      :title="title"
      :nav="nav"
      :connector_id="connectorId"
      icon="fa fa-dashboard"
    />
    <div class="tab-pane active" id="edit" role="tabpanel" aria-labelledby="">
      <EmptyListAlert
        v-if="isEmpty"
        class="inner-panel"
        buttonLabel="add_new_device"
        importLabel="import_devices"
        :hideImport="!$can('manage', 'EstacaoCadastro') || hideImport()"
        :isInstance="connector && connector.base_model_id != null"
        @import="importDevices()"
        :newItemPath="newItemPath"
      />
      <SearchableTable
        v-else
        class="searchable-table"
        :items="list"
        :fields="fields"
        :commands="commands"
        :multiColumnOrder="false"
        :clientSort="true"
        :pagination="pagination"
        :maxResult="maxResult"
        :deepSearch="false"
        :multiSelection="multiSelection"
        :disabled="cloneEnabled"
        @select="onSelect"
        @command="onCommand"
        @loadNextPage="onLoadNextPage"
        @multiColumnSort="onMultiColumnSort"
        @nItems="maxResult = $event"
        ref="stbl"
      >
        <template #extraButtons>
          <button
            id="export"
            class="btn btn-default"
            :disabled="list && list.length == 0"
            @click.stop.prevent="downloadCSV(connector)"
            :title="$t('hints.button_export')"
          >
            <i class="fa fa-file-excel-o" />
          </button>
          <button
            v-if="multiSelection.key"
            id="mass_remove"
            class="btn btn-default"
            :disabled="!nSelected"
            @click="massRemove"
            :title="$t('mass_remove')"
          >
            <i class="fa fa-trash-o" />
          </button>
        </template>
        <template #statistics="{ itemsInPage }">
          <ResourceStatistics
            resource="device"
            :total="maxResult"
            :showing="itemsInPage"
            icon="portal-icon device[solid]"
          />
        </template>
        <template v-slot:screen_id="entry">
          <ScreenSelectionCell :item="entry.item">
            <template #before>
              <i class="fa fa-alarm"></i>
            </template>
          </ScreenSelectionCell>
        </template>
        <template v-slot:toggle="entry">
          <div v-if="entry.item.connector.base_model">-</div>
          <ResourceToggle :item="entry.item" v-else />
        </template>
      </SearchableTable>
    </div>
    <div class="tab-pane" id="list" role="tabpanel" aria-labelledby=""></div>
    <ModalImportFile
      :connectorId="connector_id"
      :entity="importEntity"
      @close="clearImportEntity"
      @resource_imported="resourceImported"
    />
  </section>
</template>

<script>
import DashboardEditPickerBase from "@/components/registration/dashboard-edit-picker-base.vue";
import SearchableTable from "@/components/searchable-table.vue";
import ResourceStatistics from "@/components/statistics/resource-statistics.vue";
import DeviceService from "@/services/device.js";
import ConnectorService from "@/services/connector.js";
import EmptyListAlert from "@/components/registration/empty-list-alert.vue";
import ModalImportFile from "@/components/modal-import-file.vue";
import ScreenSelectionCell from "@/components/registration/screen-selection-cell.vue";
import ResourceToggle from "@/components/registration/resource-toggle.vue";
import { isMQTT } from "@/services/connector.js";
import { deviceListAdapter } from "@/services/device";

export default {
  name: "DashboardEditDevicePicker",
  components: {
    SearchableTable,
    ResourceStatistics,
    EmptyListAlert,
    ModalImportFile,
    ScreenSelectionCell,
    ResourceToggle
  },
  extends: DashboardEditPickerBase,
  props: {
    connector_id: {
      type: Number,
      required: true,
      default: 0
    },
    formPicker: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      entity: "device",
      title: "loading",
      nav: null,
      items: null,
      select: "edit",
      importEntity: ""
    };
  },
  computed: {
    fields() {
      const hasVirtual = (this?.items || []).some(
        ({ data_collector_device_id, id }) =>
          data_collector_device_id &&
          parseInt(data_collector_device_id) != parseInt(id)
            ? true
            : false
      );
      return [
        {
          name: "id",
          title: "ID",
          visible: false
        },
        {
          name: "reference_id",
          title: "reference",
          visible: false
        },
        {
          name: "data_collector_device_id",
          title: "collector_id",
          visible: false,
          parser(item) {
            return item.data_collector_device_id == item.id
              ? "-"
              : item.data_collector_device_id;
          }
        },
        {
          name: "name",
          title: "device",
          format: (value) => {
            return value;
          },
          style: (item) => {
            return item?.is_reference
              ? { "font-weight": "700", opacity: "0.85" }
              : {};
          }
        },
        {
          name: "device_address",
          title: "address",
          visible: this.isMQTT ? false : true,
          style: (item) => {
            return item?.is_reference
              ? { "font-weight": "700", opacity: "0.85" }
              : {};
          }
        },
        {
          name: "screen_id",
          title: "screen",
          // parser: (device) => {
          //   let screenId = device?.screen_id || "";
          //   if (!screenId && device?.connector?.screen_id) {
          //     screenId = device.connector.screen_id;
          //   }
          //   return (screenId && this.getScreenById(screenId)?.name) || "";
          // },
          style: (item) => {
            return item?.is_reference
              ? { "font-weight": "700", opacity: "0.85" }
              : {};
          }
        },
        {
          name: "virtual",
          title: "Virtual",
          visible: hasVirtual,
          parser: (item) => {
            return this.$t(
              item?.data_collector_device_id &&
                parseInt(item?.data_collector_device_id) != parseInt(item.id)
                ? "yes"
                : "no"
            );
          },
          style: (item) => {
            return item?.is_reference
              ? {
                  "font-weight": "700",
                  opacity: "0.75"
                }
              : null;
          }
        },
        {
          name: "status",
          title: "status",
          parser: (device) => {
            return this.$tc(device.is_connected ? "connected" : "disconnected");
          },
          style: (item) => {
            return item?.is_reference
              ? { "font-weight": "700", opacity: "0.85" }
              : {};
          }
        },
        {
          name: "toggle",
          title: "enabled",
          parser: (item) => {
            return item.enabled ? 0 : 1;
          },
          style() {
            return { "text-align": "center" };
          }
        }
      ];
    },
    defaultDeviceList() {
      let r = {};
      (this.items || []).forEach(({ id, connector }) => {
        r[connector.id] =
          !r[connector.id] || id < r[connector.id] ? id : r[connector.id];
      });
      return r;
    },
    commands() {
      if (!this) return [];
      let items = [
        {
          name: "create",
          title: "new",
          icon: "fa fa-plus",
          enabled: () => {
            return (
              this.$can("manage", "EstacaoCadastro") &&
              !this.cloneEnabled &&
              (!this.connector || !this.connector.base_model_id)
            );
          },
          commands: []
        },
        {
          name: "edit",
          title: "edit",
          icon: "fa fa-pencil",
          enabled: (item) => {
            return (
              !this.nSelected &&
              this.$can("manage", "EstacaoEscrita") &&
              !this.cloneEnabled
            );
          }
        },
        {
          name: "clone",
          title: "clone",
          icon: "fa fa-copy",
          enabled: (item) => {
            if (!this.$can("manage", "EstacaoCadastro") || this.cloneEnabled)
              return false;
            if (
              isMQTT(item.connector) &&
              this.defaultDeviceList[item?.connector?.id] == item.id
            )
              return false;
            return !this.nSelected && !item.connector.base_model_id;
          }
        },
        {
          name: "remove",
          title: "remove",
          icon: "fa fa-trash",
          enabled: (item) => {
            if (this.nSelected) return false;
            if (!this.$can("manage", "EstacaoCadastro") || this.cloneEnabled)
              return false;
            if (
              item.data_collector_device_id &&
              item.data_collector_device_id != item.id
            )
              return true;
            if (
              isMQTT(item.connector) &&
              this.defaultDeviceList[item?.connector?.id] == item.id
            )
              return false;
            if (this.justOneCollector) return false;
            return !item.connector.base_model_id;
          }
        },
        { name: "-" },
        {
          name: "open",
          title: "data_list",
          icon: "glyphicon glyphicon-stats",
          enabled: () => {
            return !this.nSelected && !this.cloneEnabled;
          }
        },
        {
          name: "addChild",
          title: this.$t("add_new_data"),
          icon: "fa fa-plus",
          enabled: (item) => {
            if (!this.$can("manage", "EstacaoCadastro") || this.cloneEnabled)
              return false;
            return (
              !this.nSelected &&
              !item.connector.base_model_id &&
              !item.reference_device_id
            );
          }
        }
      ];
      if (this.cloneEnabled || this?.connector?.base_model_id) return items;
      if (this.$can("manage", "EquipamentoCadastro"))
        items[0].commands.push({
          name: "newConnector",
          title: "new_connector",
          icon: "fa fa-plus"
        });
      if (!(this.$store.getters["dashboard/connectorList"] || []).length)
        return items;
      if (this.$can("manage", "EstacaoCadastro"))
        items[0].commands.push({
          name: "newDevice",
          title: "new_device",
          icon: "fa fa-plus"
        });
      if (this.$can("manage", "DadoCadastro"))
        items[0].commands.push({
          name: "newData",
          title: "new_data",
          icon: "fa fa-plus"
        });
      if (!this.isFreePlan && this.$can("manage", "AlarmeCadastro"))
        items[0].commands.push({
          name: "newAlarm",
          title: "new_alarm",
          icon: "fa fa-plus"
        });
      if (this.$can("manage", "EstacaoCadastro") && !this.hideImport())
        items[0].commands.push({
          name: "importDevices",
          title: "import_devices",
          icon: "fa fa-plus"
        });
      if (this.$can("manage", "DadoCadastro") && !this.hideImport())
        items[0].commands.push({
          name: "importData",
          title: "import_data",
          icon: "fa fa-plus"
        });
      if (
        !this.isFreePlan &&
        this.$can("manage", "AlarmeCadastro") &&
        !this.hideImport()
      )
        items[0].commands.push({
          name: "importAlarms",
          title: "import_alarms",
          icon: "fa fa-plus"
        });
      return items;
    },
    list() {
      return this.items;
    },
    justOneCollector() {
      return (
        (this?.items || []).filter((item) => {
          return (
            !item.data_collector_device_id ||
            (item.data_collector_device_id &&
              parseInt(item.data_collector_device_id) == parseInt(item.id))
          );
        }).length == 1
      );
    },
    newItemPath() {
      return `/dashboard/edit/connector/${this.connector_id || 0}/device/0`;
    },
    connector() {
      return (
        this?.parent ||
        (this.$store.getters["dashboard/connectorList"] || []).find(
          ({ id }) => id == this.connector_id
        )
      );
    }
  },
  watch: {
    parent(n) {
      if (n) {
        this.$set(this, "nav", {
          previous: `/dashboard/edit/connector/`,
          items: [
            { name: "connector_plural", url: "/dashboard/edit/connector" },
            { name: n.name, url: `/dashboard/edit/connector/${n.id}` },
            {
              name: "device_plural",
              url: `/dashboard/edit/connector/${n.id}/device`
            }
          ]
        });
      }
    },
    connector_id(n, o) {
      if (o && n && n != o) {
        this.items = [];
        this.fetchItems();
      }
    }
  },
  methods: {
    removalMessage(item) {
      let msg = "";

      // In mass remove mode
      if (item && Array.isArray(item)) {
        return this.warningContent(
          "device",
          this.$tc("n_records_selected", item.length, { amount: item.length }),
          "you_wont_be_able_to_revert_this"
        );
      }

      if (item.connector.base_model) {
        let message = this.$t("removal_model_message");
        let text = this.$t("you_wont_be_able_to_revert_this");
        let field_name = this.$tc("device");
        let value = item.name;
        let cls = "fa fa-exclamation-triangle";
        let warning = `<p>${message}<br/><div class="text-warning"><i class="${cls}"></i> ${text}</div></p>`;
        let html = `<b>${field_name}</b>: ${value}${warning}`;
        msg = this.wrap(html); // can be implemented at child level
      }
      return msg;
    },
    create(command) {
      if (this.isModel) {
        this.$router.push(
          "/dashboard/edit/connector/" + this.connectorId + "/device/0"
        );
        return;
      }
      this.validateResourceQuota(
        "device_plural",
        (this.contract && this.contract.maximum_connectors) || 1, // there is still no max_device on contract
        (this.contract && this.contract.registered_connectors) || 0
      ).then((resp) => {
        if (resp == "proceed") {
          this.$router.push(
            "/dashboard/edit/connector/" + this.connectorId + "/device/0"
          );
        } else if (resp == "upgrade") {
          this.$router.push("/dashboard/plan");
        }
      });
    },
    open(command) {
      this.$router.push(
        "/dashboard/edit/connector/" +
          this.connectorId +
          "/device/" +
          command.target.id +
          "/data"
      );
    },
    edit(command) {
      this.$router.push(
        "/dashboard/edit/connector/" +
          command.target.connector.id +
          "/device/" +
          command.target.id
      );
    },
    clone(command) {
      this.$router.push(
        "/dashboard/edit/connector/" +
          command.target.connector.id +
          "/device/" +
          command.target.id +
          "?a=c"
      );
    },
    resourceImported(response, entity) {
      let msg = this.$t("success_import_message");
      this.validateSaveResponse(response, msg);
      this.showAlert();

      if (response && response instanceof Array) {
        this.$emit("resource_imported", entity);
        this.fetchItems();
      }
    },
    hideImport() {
      return !this.connector || this.connector.base_model_id != null;
    },
    remove(command) {
      let device = command.target;
      let config = {
        item: device,
        type: "device",
        resource_key: "device_id",
        rule: "EstacaoEscrita",
        service: this.service
      };
      this.validateAndRemove(config, (removed) => {
        if (removed) {
          if (!this.connector.model_base) {
            this.$store.dispatch("dashboard/removeResources", [
              { device_id: device.id }
            ]);

            // Se for dispositivo virtual de referência, verificar se
            // ainda existe algum outro dispositivo que faz referência, senão
            // remove o flag "is_reference" localmente do referenciado
            if (device.reference_device_id) {
              let reference_devices = (this.items || []).filter((item) => {
                return item.reference_device_id == device.reference_device_id;
              });

              if (reference_devices.length == 0) {
                let referenced_device = this.items.find(
                  ({ id }) => id == device.reference_device_id
                );
                referenced_device.is_reference = false;
                this.$store.dispatch(
                  "dashboard/setDeviceValue",
                  referenced_device
                );
              }
            }

            this.$emit("devices", this.items);
            return;
          }
          // virtual device dependencies (model only)
          let items = this.items;
          let lst = (this.$store.getters["dashboard/deviceList"] || [])
            .filter(
              ({ data_collector_device_id }) =>
                data_collector_device_id == device.id
            )
            .map(({ id, reference_id }) => {
              items = items.filter((item) => item.id != id);
              return reference_id;
            })
            .concat([device.reference_id]);
          if (lst.length) {
            // since several other instances may have these devices, they also needs to be updated
            lst = (this.$store.getters["dashboard/deviceList"] || [])
              .filter(({ reference_id }) => lst.indexOf(reference_id) >= 0)
              .map(({ id }) => ({ device_id: id }));
            this.$set(this, "items", items);
            this.$store.dispatch("dashboard/removeResources", lst);
            this.$emit("devices", this.items);
          }
        }
      });
    },
    massRemove(command) {
      let self = this;

      if (this.multiSelection.values.length == 0) {
        return;
      }

      // Validação do que vai ser excluído
      let selectedItems = this.list.filter(
        (item) => self.multiSelection.values.indexOf(item.id) != -1
      );

      let blockedItems = selectedItems.filter(
        (item) =>
          (this.connector.base_model_id != null &&
            (item.data_collector_device_id == null ||
              item.data_collector_device_id == item.id)) ||
          self.list.length == 1
      );

      let doMassRemove = () => {
        let blockedItemsIDs = [];

        if (blockedItems.length > 0) {
          blockedItemsIDs = blockedItems.map((item) => item.id);
          self.multiSelection.values = self.multiSelection.values.filter(
            (id) => blockedItemsIDs.indexOf(id) == -1
          );
        }

        if (self.multiSelection.values.length == 0) {
          return;
        }

        let removed_devices = (this.items.slice(0) || []).filter(
          ({ id }) => this.multiSelection.values.indexOf(id) >= 0
        );

        let config = {
          items: this.multiSelection.values,
          type: "device",
          resource_key: "device_id",
          rule: "EstacaoEscrita",
          service: this.service
        };
        let callback = (removed) => {
          if (!removed) return;

          removed_devices.forEach((device) => {
            // Se for dispositivo virtual de referência, verificar se
            // ainda existe algum outro dispositivo que faz referência, senão
            // remove o flag "is_reference" localmente do referenciado
            if (device.reference_device_id) {
              let reference_devices = (this.items || []).filter((item) => {
                return item.reference_device_id == device.reference_device_id;
              });

              let referenced_device = this.items.find(
                ({ id }) => id == device.reference_device_id
              );

              if (
                reference_devices.length == 0 &&
                referenced_device.length >= 0
              ) {
                referenced_device.is_reference = false;
                this.$store.dispatch(
                  "dashboard/setDeviceValue",
                  referenced_device
                );
              }
            }
          });

          self.multiSelection.values = [];
          this.$emit("devices", this.items);
        };
        this.validateAndMassRemove(config, callback);
      };

      if (blockedItems.length > 0) {
        self
          .$swal({
            title: self.$t("some_selected_items_cant_be_removed"),
            text: self.$t("wish_to_unselect"),
            icon: "warning",
            buttons: [self.$t("cancel"), self.$t("yes_proceed")]
          })
          .then((result) => {
            if (result) doMassRemove();
          });
      } else {
        doMassRemove();
      }
    },
    addChild(command) {
      this.$router.push(
        "/dashboard/edit/connector/" +
          this.connectorId +
          "/device/" +
          command.target.id +
          "/data/0"
      );
    },
    fetchItems(URL) {
      let self = this;
      let url = URL || "";
      let query = null;
      if (!url) {
        query = {};
        query.contract_id = this.contract.id;
        if (self.connectorId) query.connector_id = self.connectorId;
      }
      self.busy = true;
      self.fetch(query, url).then(() => {
        if (self.items.length) {
          deviceListAdapter(self.items);
          self.$set(this, "parent", self.items[0]?.connector);
        } else {
          if (self.connectorId) {
            let srv = new ConnectorService();
            srv
              .get(self.connectorId, self.$store.getters["user/contract_id"])
              .then((result) => {
                if (result) {
                  self.$set(this, "parent", result);
                }
              });
          }
        }
        self.title = "device_list";
        self.busy = false;
        this.$emit("devices", self.items);
      });
    },
    log() {
      if (!this.connector_id) return;
      console.table(
        Object.fromEntries(
          this.$store.getters["dashboard/deviceList"]
            .filter(
              ({ connector_id }) =>
                parseInt(connector_id) == parseInt(this.connector_id)
            )
            .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
            .map(({ id, name, reference_id }) => [id, { reference_id, name }])
        )
      );
    }
  },
  beforeCreate() {
    this.service = new DeviceService();
    this.$emit("rule", "EstacaoAcesso");
  }
};
</script>

<style scoped>
section > .tab-pane {
  margin: 0 0 30px 0;
  padding: 0 15px 50px 15px;
  overflow-x: auto;
}
.searchable-table {
  padding-bottom: 100px;
}
</style>
